import logo from './logo.svg';
import './App.css';
import Hero from './Components/Hero/Hero';
import About from './Components/About/About';
import Tokenomics from './Components/Tokenomics/Tokenomics';
import Slider from './Components/Slider/Slider';
import Articles from './Components/Articles/Articles';

function App() {
  return (
    <div className='max-w-[100vw] overflow-clip bg-[#E0FAFF]'>
      <Hero/>
      <Slider/>
      <About/>
      <Slider/>
      <Articles/>
      <Slider/>
      <Tokenomics/>
    </div>
  );
}

export default App;
