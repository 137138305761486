import React from 'react'

const Tokenomics = () => {
    return (
        <div className='body_2 pb-32 font_one'>
            <div className='flex justify-center pb-10 pt-10 md:pt-24 md:pb-20'>
                <img className='' src={require('../../Assets/logo.png')} />  
            </div>
            <div className='flex justify-center'>
                <img className='h-[300px] md:h-[700px]' src={require('../../Assets/token.png')} />
            </div>
            <div className='flex  flex-col gap-4 items-center py-8 text-[20px] md:text-[40px] justify-center'>
                <p>Tax: 0% (BUY/SELL)</p>
                <p>Supply: 1 Billion</p>
                <p>Lp: Burned</p>
            </div>
        </div>
    )
}

export default Tokenomics