import React from 'react'

const Hero = () => {
    return (
        <div className='relative body_bg h-[100vh] w-[100vw] flex justify-center items-end sm:items-center '>
            <div className='absolute  top-[50px] md:top-[100px] w-[100vw] flex justify-center'>
                <img src={require('../../Assets/pts.png')} />

            </div>
            <div class="flex justify-center gap-2 pb-20 sm:pb-0 font_one ">
                <a href='https://x.com/minimoonerc'>
                <button className="bg-btn px-[40px] rounded-xl p-3 border-[4px] border-black text-[24px] text-[black]">Twitter</button>
                </a>
                <a href='https://t.me/minimooneth'>
                <button className="bg-btn px-[40px] rounded-xl p-3 border-[4px] border-black text-[24px] text-[black]">Telegram</button>
                </a>
            </div>
        </div>
    )
}

export default Hero