import React from 'react'

const About = () => {
    return (
        <div className='body_2'>
            <section class="text-gray-600 body-font font_one">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 class="title-font text-[70px] leading-[70px] mb-4 font-medium text-gray-900">Mini Moon - The Degen
                           
                        </h1>
                        <p class="mb-8 leading-relaxed text-[18px] sm:text-[20px] md:text-[24px]">
                        Meet Mini Moon, the cheeky satellite who ditched the whole "steady orbit" thing after a few days. Instead of calmly circling Earth, he’s now a full-blown degen gambler, betting on cosmic events and chasing high-risk, high-reward chaos. Why settle for predictable when you can roll the dice with black holes? Mini Moon’s all about luck, mischief, and the thrill of the gamble in the vastness of space.</p>
                        <div class="flex justify-center gap-2">
                            <a href='https://t.me/minimooneth'>
                            <button className="bg-btn px-[40px] rounded-xl p-3 border-[4px] border-black text-[24px] text-[black]">Buy $MOON</button>

                            </a>
                            {/* <button className="bg-btn px-[40px] rounded-xl p-3 border-[4px] border-black text-[24px] text-[black]">Button</button> */}
                        </div>
                    </div>
                    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src={require('../../Assets/p2.png')} />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About