import React from 'react'

const Articles = () => {
    const articles = [
        {
            id: 1,
            title: "Earth To Get A New Moon On Sept. 29, Scientists Say",
            description: "Say hello to 2024 PT5, Earth’s newest mini-moon. Expected to go into orbit of Earth for a couple of months from Sept. 29,....",
            link: "https://www.forbes.com/sites/jamiecartereurope/2024/09/20/earth-to-get-a-new-moon-on-sept-29-scientists-say/",
            images: "https://ichef.bbci.co.uk/ace/standard/624/cpsprodpb/81d5/live/80b2e680-7b21-11ef-9617-cff57bf511c9.jpg.webp"
        },
        {
            id: 2,
            title: "What is 2024 PT5? Earth to get second 'mini' moon",
            description: "Nasa telescopes have spotted a small asteroid due to enter our planet's orbit from 29 September....",
            link: 'https://www.bbc.co.uk/newsround/articles/czd133dp7emo',
            images: "https://imageio.forbes.com/specials-images/imageserve/1193267511/Asteroid-approaching-planet-Earth--elements-of-this-image-furnished-by-NASA/960x0.jpg?format=jpg&width=1440"
        },
        {
            id: 3,
            title: "Earth Is Getting a New Mini Moon",
            description: "A small asteroid, 2024 PT5, will spend the next two months alongside our planet as a mini moon before swooping back to deep space....",
            link: 'https://www.scientificamerican.com/article/tiny-asteroid-will-briefly-become-earths-mini-moon/',
            images: "https://static.scientificamerican.com/dam/m/6fe5195eccc4b4ef/original/small_asteroid.jpg?w=900"
        }
    ]
    return (
        <div className='body_2'>
            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div>
                        <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-12 text-center font_one">Latest Article</h1>
                    </div>
                    <div class="flex flex-wrap -m-4">
                        {
                            articles?.map((article, index) => {
                                return (
                                    <a href={article?.link} class="p-4 md:w-1/3 font_one">
                                        {/* <div class="p-4 md:w-1/3 font_one"> */}
                                            <div class="h-full border-2 border-black border-opacity-100 rounded-lg overflow-hidden">
                                                <img class="lg:h-48 md:h-36 w-full object-cover object-center" src={article?.images} alt="blog" />
                                                <div class="p-6">
                                                    <h1 class="title-font text-lg font-medium text-gray-900 mb-3">{article?.title}</h1>
                                                    <p class="leading-relaxed mb-3">{article?.description}</p>
                                                </div>
                                            </div>
                                        {/* </div> */}
                                    </a>

                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Articles